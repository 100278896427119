import React from 'react'
import RelatedProjectsSliderBlock from './related-projects-slider-block'

function ArtistProjectSlider(props) {
  const items = [{ title: 'Featured Projects', items: props.items }]
  return (
    <RelatedProjectsSliderBlock
      items={items}
      posterImage={true}
      className="artist-project-slider"
    />
  )
}

export default ArtistProjectSlider
